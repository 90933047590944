/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement("section", null, React.createElement("div", null, React.createElement(_components.h2, null, "Les meilleurs quartiers pour se loger dans la ville de Luxembourg"), React.createElement("p", null, "Pour se loger, la ville de Luxembourg s’articule en trois quartiers principaux :"), React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lu/luxembourg/villehaute.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Ville-Haute"), " : le centre historique de la ville de Luxembourg, avec entre autres la cathédrale et le palais grand-ducal."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lu/luxembourg/luxemburg-gare.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Gare de Luxembourg"), ", au sud : quartier avec de nombreux commerces et restaurants."), "\n", React.createElement(_components.li, null, React.createElement("a", {
    href: "https://www.booking.com/district/lu/luxembourg/luxemburg-kirchberg.fr.html?aid=397676&label=MytourofEuropeApp",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Plateau du Kirchberg"), ", au nord-est : le siège des institutions européennes, du secteur financier et bancaire, mais aussi de la Philharmonie et des musées les plus récents."), "\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
